import React, { useEffect, useState } from "react";
import { Col, Row } from "react-bootstrap";
import { FaCircleXmark } from "react-icons/fa6";
import {
  useDeleteCommentMutation,
  useGetPostDetailsWithCommentQuery,
} from "../../Data/services/postApi";
import Toast, { SuccessToast } from "../../Hooks/useToast";
import Comment from "../Comment/comment";
import { Description, SubTitle } from "../CustomComponents";
import ModalContainer from "../ModalContainer";
import CommentShimmer from "../Shimmers/comment_shimmer";
import UserAvatarWithNameAndTag from "../UserAvatar/UserAvatarWithNameAndTag";
import cssStyles from "./styles/viewPostModal.module.scss";

const ViewPostModal = ({ handleViewModalClose, data }) => {
  const slug = data.slug;
  const [comments, setComments] = useState([]);

  // console.log(data);

  const {
    data: postData,
    isSuccess,
    isLoading,
    isFetching,
    refetch,
  } = useGetPostDetailsWithCommentQuery(slug);

  console.log(postData);
  console.log(comments);

  // const [mounted, setMounted] = React.useState(false);
  // useEffect(() => {
  //   setMounted(true);
  // }, []);

  useEffect(() => {
    if (postData) {
      console.log(postData.data.post_comments);
      setComments(postData.data.post_comments);
    }
  }, [postData]);

  // if (!mounted) {
  //   return;
  // }

  const [deleteComment, { isLoading: isDelete }] = useDeleteCommentMutation();

  const handleCommentDelete = async (slug) => {
    console.log(slug);

    try {
      const { data } = await deleteComment(slug);

      console.log(data);

      if (data.code === 200) {
        await refetch();
        SuccessToast(data.message);
      }
    } catch (error) {
      console.log(error);
      Toast(error.message, "error");
    }
  };

  return (
    <div>
      <ModalContainer handleClose={handleViewModalClose}>
        <div className={cssStyles.modalContainer}>
          <div className="d-flex align-items-center w-100 justify-content-between mb-4">
            <p className="fs-4 fw-medium m-0 black-text">View Post</p>
            <span className="bg-dark bg-opacity-75 d-flex p-3 rounded-5">
              <FaCircleXmark
                role="button"
                onClick={handleViewModalClose}
                style={{ cursor: "pointer" }}
              />
            </span>
          </div>
          <div className="w-100">
            <Row className="w-100 h-100">
              <Col md={6} sm={12} className="">
                <div className="d-flex align-items-start w-100 h-100 flex-wrap my-2">
                  {data.post_images.length > 0 ? (
                    data.post_images.map((image, index) => {
                      return (
                        <React.Fragment>
                          <img
                            src={image.image_url}
                            alt=""
                            width="100%"
                            className={`${cssStyles.workoutImage} rounded-3`}
                          />
                          <br></br>
                        </React.Fragment>
                      );
                    })
                  ) : (
                    <img
                      src={data.video_thumbnail}
                      alt=""
                      width="100%"
                      className={`${cssStyles.workoutImage}`}
                    />
                  )}
                </div>
              </Col>
              <Col md={6} sm={12} className="">
                <div className="d-flex flex-column justify-content-start align-items-top w-100 h-100">
                  {
                    // <SubTitle classes="lc-1 black-text" text="Post Title" />
                  }
                  {
                    //                   <Description
                    //                     classes="my-1 black-text"
                    //                     text="Published date: 4/29/2024"
                    //                   />
                  }

                  <div className="d-flex flex-row justify-content-between align-items-center">
                    <UserAvatarWithNameAndTag
                      avatarImage={data.user_image}
                      userName={data.user_name}
                      // userTag={"@username"}
                    />
                  </div>

                  <Description
                    classes="my-1 text-black-50"
                    text="Description"
                  />

                  <Description
                    classes="my-2 black-text"
                    text={data.post_description}
                  />

                  <hr></hr>

                  <SubTitle
                    classes="lc-1 fs-5 mb-4 black-text"
                    text="Comments"
                  />

                  {/* Shimmer */}
                  {isLoading ? <CommentShimmer /> : ""}

                  {isSuccess ? (
                    comments.length > 0 ? (
                      comments.map((comment, i) => {
                        // console.log(comment);
                        // console.log(i);
                        return (
                          <>
                            <Comment
                              key={i}
                              userName={`${comment.user.first_name} ${comment.user.last_name}`}
                              // tag="@abcdefghijk"
                              avatarImage={comment.user.image_url}
                              // commentTime={moment(
                              //   comment.createdAt
                              // ).fromNow()}
                              commentText={comment.comment}
                              commentSlug={comment.slug}
                              onCommentDelete={handleCommentDelete}
                              isDelete={isDelete}
                            />
                            {i <= comments.length ? <hr></hr> : ""}
                          </>
                        );
                      })
                    ) : (
                      <Description
                        classes="my-2 black-text"
                        text={"No Comment Found."}
                      />
                    )
                  ) : (
                    ""
                  )}
                </div>
              </Col>
            </Row>
          </div>
        </div>
      </ModalContainer>
    </div>
  );
};

export default ViewPostModal;

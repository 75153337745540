import React, { useEffect, useState } from "react";

import { BREAKPOINTS } from "../../constants";
import DesktopLayout from "./DesktopLayout";
import MobileLayout from "./MobileLayout";
import { ROUTES } from "./contants";

export default function Setting() {
  const [route, setRoute] = useState(ROUTES.CHANGE_PASSWORD);
  const [isMobile, setIsMobile] = useState(
    window.innerWidth <= BREAKPOINTS.MOBILE
  );

  useEffect(() => {
    window.addEventListener("resize", handleWindowSizeChange);

    return () => window.removeEventListener("resize", handleWindowSizeChange);
  });

  // // inject data
  // const { data, isSuccess } = useGetStaticPagesQuery({
  //   refetchOnMountOrArgChange: true,
  // });

  function handleWindowSizeChange() {
    if (!isMobile && window.innerWidth <= BREAKPOINTS.MOBILE) {
      setRoute("");
    } else if (isMobile && window.innerWidth > BREAKPOINTS.MOBILE) {
      setRoute(ROUTES.EDIT_PROFILE);
    }
    setIsMobile(window.innerWidth <= BREAKPOINTS.MOBILE);
  }

  return isMobile ? (
    <MobileLayout route={route} setRoute={setRoute} />
  ) : (
    <DesktopLayout route={route} setRoute={setRoute} />
  );
}
